<template>
  <div>
    <slider-input
      v-model="value"
      tooltip="none"
      :process="process"
      :enable-cross="false"
      :min-range="0"
      :interval="1"
      @change="updateAnswer"
    >
      <template v-slot:process="{ start, end, style, index }">
        <div class="vue-slider-process" :style="style">
          <div
            :class="[
              'merge-tooltip',
              'vue-slider-dot-tooltip-inner',
              'vue-slider-dot-tooltip-inner-top'
            ]"
          >
            <b>{{ transports[index] }}</b>
            {{ parseInt(end - (start / 100) * 100) }}%
          </div>
        </div>
      </template>
    </slider-input>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'SliderAnswers',
  props: {
    answers: {
      default: () => {},
      type: Object
    },
    other: {
      default: () => {},
      type: Object
    }
  },
  data: function() {
    return {
      value: [12, 76],
      transports: ['Walk', 'Car', 'Train'],
      process: val => [
        [0, val[0]],
        [val[0], val[1]],
        [val[1], 100]
      ]
    }
  },
  methods: {
    updateAnswer: function(answerValue) {
      this.$emit('answer-changed', answerValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.merge-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -15px);
}

.vue-slider {
  height: 14px !important;
  margin-top: 70px;
}

.vue-slider-process {
  background-color: #54c6cc;

  &:nth-child(3n + 2) {
    background-color: #8ad877;
  }

  &:nth-child(3n + 3) {
    background-color: #ffe952;
  }
}

/deep/ .vue-slider-dot {
  height: 2rem !important;
  width: 2rem !important;
}

/deep/ .vue-slider-dot-handle {
  background-color: #f7f8f3;
  box-shadow: none;
  border: 2px solid #d5d5d5;
}

.vue-slider-dot-tooltip-inner {
  background-color: #f7f8f3;
  border: 1px solid #979797;
  color: #4a4a4a;

  b {
    display: block;
  }
}
</style>
