<template>
  <div>
    <SelectInput :options="answers.options" @change="updateAnswer" />
    <div v-if="other.enabled">
      <div
        v-if="other.type === 'button'"
        @click="selectOtherAnswer(other.answer)"
      >
        <Button size="small" class="btn btn-default btn-block btn-survey">
          {{ other.answer.text }}
        </Button>
      </div>
      <div v-if="other.type === 'input'"></div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'DropdownAnswers',
  props: {
    answers: {
      default: () => {},
      type: Object
    },
    other: {
      default: () => {},
      type: Object
    }
  },
  data: function() {
    return {
      answerText: ''
    }
  },
  methods: {
    selectOtherAnswer(answer) {
      this.$emit('answer-instant', answer)
    },
    updateAnswer: function(answerValue) {
      this.$emit('answer-changed', answerValue)
    }
  }
}
</script>

<style></style>
