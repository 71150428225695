var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('slider-input',{attrs:{"tooltip":"none","process":_vm.process,"enable-cross":false,"min-range":0,"interval":1},on:{"change":_vm.updateAnswer},scopedSlots:_vm._u([{key:"process",fn:function(ref){
var start = ref.start;
var end = ref.end;
var style = ref.style;
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-process",style:(style)},[_c('div',{class:[
            'merge-tooltip',
            'vue-slider-dot-tooltip-inner',
            'vue-slider-dot-tooltip-inner-top'
          ]},[_c('b',[_vm._v(_vm._s(_vm.transports[index]))]),_vm._v(" "+_vm._s(parseInt(end - (start / 100) * 100))+"% ")])])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }