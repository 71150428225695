<template>
  <div>
    <div v-for="(answer, index) in localAnswers.options" :key="index">
      <CheckboxInput
        :class="{ 'btn-primary': answer.isSelected }"
        class="btn btn-default btn-block"
        @onClick="selectAnswer(answer)"
      >
        {{ answer.text }}
      </CheckboxInput>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'CheckboxAnswers',
  props: {
    answers: {
      default: () => {},
      type: Object
    },
    other: {
      default: () => {},
      type: Object
    }
  },
  data: function() {
    return {
      localAnswers: {}
    }
  },

  mounted() {
    this.localAnswers = _.cloneDeep(this.answers)
  },

  methods: {
    getType(isSelected) {
      return isSelected ? 'primary' : 'transparent'
    },
    selectAnswer: function(answerValue) {
      answerValue.isSelected = !answerValue.isSelected
      this.$emit('answer-changed', this.localAnswers)
    }
  }
}
</script>

<style></style>
