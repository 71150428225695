<template>
  <div>
    <TextInput placeholder="Answer" :value="answerText" @input="updateAnswer" />
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'InputAnswers',
  props: {
    answers: {
      default: () => {},
      type: Object
    },
    other: {
      default: () => {},
      type: Object
    }
  },
  data: function() {
    return {
      answerText: ''
    }
  },
  methods: {
    updateAnswer: function(answerValue) {
      this.$emit('answer-changed', answerValue)
    }
  }
}
</script>

<style></style>
