<template>
  <div class="survey-container">
    <div v-if="!surveyComplete">
      <h2 class="survey__heading">{{ currentQuestion.text }}</h2>
      <p class="survey__subtext">{{ currentQuestion.subText }}</p>
    </div>

    <div v-if="!surveyComplete">
      <component
        :is="currentQuestion.type"
        :key="currentQuestion.id"
        placeholder="'Answer'"
        :answers="currentQuestion.answers"
        :other="currentQuestion.otherAnswer"
        :settings="currentQuestion.settings"
        class="survey__question"
        @answer-instant="updateQuestionResponseStateAndContinue"
        @answer-changed="updateQuestionResponseState"
      ></component>

      <div @click="testNextQuestion()">
        <Button type="secondary" size="small" class="btn-survey">
          <div v-if="currentQuestion.isFinal && !nextQuestion">
            Submit Idea
          </div>
          <div v-if="!currentQuestion.isFinal || nextQuestion">
            Next Question
          </div>
        </Button>
      </div>
    </div>
    <div v-if="surveyComplete">
      <h2>Thank you for submitting your idea</h2>
      <p>Your responses have been recorded</p>
    </div>
  </div>
  <!-- Current
      {{ currentQuestion }}
      <br />
      <br />
      Next
      {{ nextQuestion }}
      <br />
      <br />
      answers
      {{ surveySessionAnswers }} -->
</template>

<script>
import SurveyService from '../../../services/survey.service'
const surveyService = new SurveyService()
import _survey from './data'
import _ from 'lodash'
import Vue from 'vue'
import ButtonAnswersComponent from './ButtonAnswers.vue'
import CheckboxAnswersComponent from './CheckboxAnswers.vue'
import DateAnswersComponent from './DateAnswers.vue'
import InputAnswersComponent from './InputAnswers.vue'
import SliderAnswersComponent from './SliderAnswers.vue'
import DropdownAnswersComponent from './DropdownAnswers.vue'

import 'vue-slider-component/theme/default.css'
export default {
  name: 'SurveyHost',
  components: {
    'button-answers': ButtonAnswersComponent,
    'checkbox-answers': CheckboxAnswersComponent,
    'date-answers': DateAnswersComponent,
    'input-answers': InputAnswersComponent,
    'slider-answers': SliderAnswersComponent,
    'dropdown-answers': DropdownAnswersComponent
  },
  props: {
    surveyData: {
      default: () => {
        return {
          title: 'Ideas Capture',
          id: 0,
          questions: [
            {
              id: 0,
              title: '1.0',
              text: 'Give your idea a title',
              subText:
                'Keep it short and sweet, we can explore the details later',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: true,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              settings: {
                multipleChoice: false
              },
              answers: {
                options: []
              },
              requirements: []
            },
            {
              id: 1,
              title: '1.1',
              text: 'Give us some detail',
              subText: 'Be creative or as out there as you like :)',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: true,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              settings: {
                multipleChoice: false
              },
              answers: {
                options: []
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 0,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 2,
              title: '1.2',
              text: 'Choose a category image that best fits your idea',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 12,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              settings: {
                multipleChoice: false
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Biodiversity'
                  },
                  {
                    id: 1,
                    text: 'Community'
                  },
                  {
                    id: 2,
                    text: 'Energy'
                  },
                  {
                    id: 3,
                    text: 'Equality & Diversity'
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 1,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      type: Object
    },

    content: {
      default: () => {},
      type: [Object, String]
    }
  },
  data() {
    return {
      srvD: _survey,
      currentQuestion: {},
      surveySessionAnswers: {},
      surveyComplete: false
    }
  },
  computed: {
    nextQuestion() {
      return surveyService.getNextQuestion(
        this.surveyData,
        this.surveySessionAnswers
      )
    },
    currentQuestionAnswerState() {
      return this.surveySessionAnswers[this.currentQuestion.id]
    },
    questionResponseValid() {
      //This contains the logic required to select and de-select answers
      if (this.currentQuestion.type === 'checkbox-answers') {
        if (this.currentQuestion.isSkippable === true) {
          return true
        }
      }
      return true
    }
  },
  mounted() {
    this.currentQuestion = this.nextQuestion
    console.log('currentQuestion', this.currentQuestion)
    this.updateQuestionResponseState(_.cloneDeep(this.currentQuestion.answers))
  },
  methods: {
    updateQuestionResponseStateAndContinue: function(questionResponseState) {
      this.updateQuestionResponseState(questionResponseState)
      this.testNextQuestion()
    },
    updateQuestionResponseState: function(questionResponseState) {
      let _answers = _.isArray(questionResponseState.options)
        ? questionResponseState.options.filter(option => option.isSelected)
        : questionResponseState
      Vue.set(this.surveySessionAnswers, this.currentQuestion.id, {
        questionId: this.currentQuestion.id,
        answers: _answers
      })
    },
    clearAnswers: function() {
      this.surveySessionAnswers = {}
    },

    pushToStore() {
      this.$store.dispatch('levelContentBlocks/insert', {
        type: 'survey-host',
        order: 99,
        content: this.surveyData
      })
    },

    testNextQuestion: function() {
      console.log(
        'getNextQuestion',
        surveyService.getNextQuestion(this.content, this.surveySessionAnswers)
      )
      if (this.surveySessionAnswers[this.currentQuestion.id] === undefined) {
        // Answer needs to be present, something has gone wrong
      } else {
        if (this.questionResponseValid) {
          // If question is final flag is true save answers array to Firebase and finish survey
          if (this.currentQuestion.isFinal && !this.nextQuestion) {
            // Firebase save successful survey to cloud
            // this.clearAnswers()
            // // Navigate to end page, TODO probs a v-if on a final page
            // this.$router.push('summary')
            this.$store.dispatch('ideas/insert', {
              response: this.surveySessionAnswers
            })
            this.surveyComplete = true
          } else {
            // Load next question
            this.currentQuestion = _.cloneDeep(this.nextQuestion)
            this.updateQuestionResponseState(
              _.cloneDeep(this.currentQuestion.answers)
            )
          }
        }
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-next {
  margin-top: 60vh;
}

.survey-container {
  margin: 10px 0;
  background: #f7f8f3;
  border-radius: 8px;
  padding: 20px;
}

.survey__heading {
  font-size: 24px;
  font-weight: 400;
  color: #002c3e;
}

.survey__subtext {
  color: $color-black;
  font-size: 14px;
}

.survey__question {
  margin-bottom: 2rem;
}
</style>
