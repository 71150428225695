<template>
  <div>
    <Datepicker
      placeholder="Select a date"
      :value="answerText"
      input-class="datepicker"
      @selected="updateAnswer"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import Datepicker from 'vuejs-datepicker'
export default {
  name: 'DateAnswers',
  components: {
    Datepicker
  },
  props: {
    answers: {
      default: () => {},
      type: Object
    },
    other: {
      default: () => {},
      type: Object
    }
  },
  data: function() {
    return {
      answerText: ''
    }
  },
  methods: {
    updateAnswer: function(answerValue) {
      this.$emit('answer-changed', answerValue)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .datepicker {
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-gray;
  padding: 0 1.6rem;
  line-height: 4.8rem;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  font-style: italic;
  color: $color-gray--darker;
  border-radius: $border-radius-small;

  &:disabled {
    color: $color-gray;
  }
}
</style>
