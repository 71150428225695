<template>
  <div class="survey-container">
    <div v-if="showResult" class="survey__result">
      <div class="survey__icon">
        <Icon
          v-if="resultObj.isCorrect"
          icon="check-circle"
          type="success"
          class="survey__icon--correct"
        />
        <Icon
          v-if="!resultObj.isCorrect"
          icon="times-circle"
          type="error"
          class="survey__icon--incorrect"
        />
      </div>
      {{ resultObj.responseText }}
      <div @click="showResult = false">
        <Button type="secondary" size="small" class="btn-survey">
          <div v-if="!resultObj.isCorrect">Retry</div>
          <div v-if="resultObj.isCorrect">Continue</div>
        </Button>
      </div>
    </div>
    <div v-if="!surveyComplete && currentQuestion.text && !showResult">
      <h2 class="survey__heading">
        {{ currentQuestion.text }}
        <span v-show="currentQuestion.validators.required">*</span>
      </h2>
      <p class="survey__subtext">{{ currentQuestion.subText }}</p>
      <b v-show="showRequired" class="survey__subtext text-red">
        Please provide an answer
      </b>
    </div>

    <div v-if="!surveyComplete && !showResult">
      <component
        :is="currentQuestion.type"
        :key="currentQuestion.id"
        placeholder="'Answer'"
        :answers="currentQuestion.answers"
        :other="currentQuestion.otherAnswer"
        :settings="currentQuestion.settings"
        class="survey__question"
        @answer-instant="updateQuestionResponseStateAndContinue"
        @answer-changed="updateQuestionResponseState"
      ></component>
      <div @click="testNextQuestion()">
        <Button type="secondary" size="small" class="btn-survey">
          <div v-if="currentQuestion.isFinal && !nextQuestion">
            Finish Quiz
          </div>
          <div v-if="!currentQuestion.isFinal || nextQuestion">
            Next Question
          </div>
        </Button>
      </div>
    </div>
    <div v-if="surveyComplete && !showResult">
      <h2>Thank you for completing the quiz</h2>
      <p>Your responses have been recorded</p>
    </div>
    <Button
      v-if="false"
      type="secondary"
      size="small"
      class="btn-survey"
      @click.native="pushToStore"
    >
      Push to FB
    </Button>
  </div>
  <!-- Current
      {{ currentQuestion }}
      <br />
      <br />
      Next
      {{ nextQuestion }}
      <br />
      <br />
      answers
      {{ surveySessionAnswers }} -->
</template>

<script>
import SurveyService from '../../../services/survey.service'
const surveyService = new SurveyService()
import _survey from './quizData'
import _ from 'lodash'
import Vue from 'vue'
import ButtonAnswersComponent from './ButtonAnswers.vue'
import CheckboxAnswersComponent from './CheckboxAnswers.vue'
import DateAnswersComponent from './DateAnswers.vue'
import InputAnswersComponent from './InputAnswers.vue'
import SliderAnswersComponent from './SliderAnswers.vue'
import DropdownAnswersComponent from './DropdownAnswers.vue'

import 'vue-slider-component/theme/default.css'
export default {
  name: 'QuizHost',
  components: {
    'button-answers': ButtonAnswersComponent,
    'checkbox-answers': CheckboxAnswersComponent,
    'date-answers': DateAnswersComponent,
    'input-answers': InputAnswersComponent,
    'slider-answers': SliderAnswersComponent,
    'dropdown-answers': DropdownAnswersComponent
  },
  props: {
    surveyData: {
      default: () => {
        return {
          title: 'Survey Title',
          id: 0,
          questions: [
            {
              id: 0,
              title: '1.0',
              text: 'Describe your current work situation',
              subText: 'Choose one',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: true,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              settings: {
                multipleChoice: false
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Homework',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: 'Flexi-working (Some office)',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Office/site based',
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: []
            },
            {
              id: 1,
              title: '1.1',
              text: 'How many days/hours do you work a week?',
              subText: 'Estimated',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 0,
                      answerIds: [0],
                      anyAnswer: false
                    }
                  ]
                }
              ]
            },
            {
              id: 2,
              title: '1.2',
              text: 'When did you start working at home',
              subText: '',
              type: 'date-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 1,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 3,
              title: '1.3',
              text:
                'Does anyone else in your household work from home? How many?',
              subText: '',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: true,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: []
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 2,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 4,
              title: '2.1',
              text:
                'How many days/hours do you work a week do you work in an office/other location?',
              subText: '',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 0,
                      answerIds: [1],
                      anyAnswer: false
                    }
                  ]
                }
              ]
            },
            {
              id: 5,
              title: '2.2',
              text: 'How many days/hours do you work a week from home?',
              subText: '',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 4,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 6,
              title: '2.3',
              text: 'When did you start working at home',
              subText: '',
              type: 'date-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 5,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 7,
              title: '2.4',
              text:
                'Does anyone else in your household work from home? How many?',
              subText: '',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 6,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 8,
              title: '4.1',
              text:
                'In an average week, how do you get to work? Select the methods of transport you use',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              settings: {
                multipleChoice: true
              },
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Motorbike',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: 'Walk',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Bicycle',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: 'Car',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 4,
                    text: 'Car pool',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 5,
                    text: 'Bus',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 6,
                    text: 'Train',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 7,
                    text: 'Tram',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 8,
                    text: 'Other, please specify',
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 7,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                },
                {
                  requirementId: 2,
                  questions: [
                    {
                      questionId: 10,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 9,
              title: '4.2',
              text:
                'What is the main factor in deciding how you’re going to get to work?',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              settings: {
                multipleChoice: false
              },
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Time saving',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: 'Reduced stress',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Financial savings',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: 'Flexbility',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Less wear and tear on car',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Convenience',
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 8,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 10,
              title: '3.1',
              text: 'How many days/hours do you work a week?',
              subText: '',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 4,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 0,
                      answerIds: [2],
                      anyAnswer: false
                    }
                  ]
                }
              ]
            },
            {
              id: 11,
              title: '4.3',
              text:
                'How far do you estimate you travel to get to work from home each day?',
              subText: '',
              type: 'input-answers',
              isFinal: false,
              settings: {
                multipleChoice: false
              },
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 9,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 12,
              title: '4.4',
              text:
                'In an average week, how much of each of your chosen methods do you use?',
              subText: '',
              type: 'slider-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 11,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 13,
              title: '4.5',
              text:
                'How long do you spend on average commuting to work and from work?',
              subText: 'Getting to work',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 12,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 14,
              title: '4.5b',
              text:
                'How long do you spend on average commuting to work and from work?',
              subText: 'Getting home',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 13,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 15,
              title: '4.6',
              text: 'How much money do you spend on your weekly commute?',
              subText: '£ Spend',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 14,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 16,
              title: '4.7',
              text:
                'Is there anything that could make your commute better or make you consider the transport you use?',
              subText: '',
              type: 'input-answers',
              isFinal: true,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {},
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 15,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 17,
              title: '5.1',
              text:
                'What equipment do you use for work? Please give name and model if possible, and if it is your own',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Laptop',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: 'Desktop',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Extra Screen',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: 'Work Mobile',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 4,
                    text: 'Other, please describe',
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 16,
                      answerIds: [],
                      anyAnswer: true
                    },
                    {
                      questionId: 7,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                },
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 3,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 18,
              title: '5.2',
              text: 'What is the main light source for your workspace?',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                answer: {
                  id: 9,
                  text: '',
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'LED',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: 'Halogen',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: 'Incandescent',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: 'Compact fluorescent (CFL)',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 4,
                    text: 'Not sure',
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 17,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 19,
              title: '5.3',
              text: 'Who is your energy supplier?',
              subText: '',
              type: 'dropdown-answers',
              isFinal: false,
              otherAnswer: {
                enabled: true,
                type: 'button',
                answer: {
                  id: 2,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Company 1',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: `Company 2`,
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 18,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 20,
              title: '5.4',
              text: 'Do you know your tariff?',
              subText: '',
              type: 'dropdown-answers',
              isFinal: false,
              otherAnswer: {
                enabled: true,
                type: 'button',
                answer: {
                  id: 2,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Tariff 1',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: `Tariff 2`,
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 19,
                      answerIds: [2],
                      anyAnswer: false
                    }
                  ]
                }
              ]
            },
            {
              id: 21,
              title: '5.5',
              text: 'What type of boiler heats your home?',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                type: 'button',
                answer: {
                  id: 6,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Gas',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: `Oil`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: `Elec`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: `Biomass`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 4,
                    text: `Heat Pump`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 5,
                    text: `Don't know`,
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 20,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                },
                {
                  requirementId: 1,
                  questions: [
                    {
                      questionId: 19,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 22,
              title: '5.6',
              text: 'Do you have a SMART meter?',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                type: 'button',
                answer: {
                  id: 6,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Yes',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: `No`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: `Not Sure`,
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 21,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 23,
              title: '5.7',
              text:
                'During warmer months, do you use anything to keep your workspace cool?',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                type: 'button',
                answer: {
                  id: 6,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Air con',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: `Fan`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: `Just open windows`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: `Nothing in particular`,
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 22,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 24,
              title: '5.8',
              text: 'Do you recycle?',
              subText: '',
              type: 'button-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                type: 'button',
                answer: {
                  id: 7,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: [
                  {
                    id: 0,
                    text: 'Food waste',
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 1,
                    text: `Paper & Card`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 2,
                    text: `Tins & Glass`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 3,
                    text: `Garden Waste`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 4,
                    text: `Clothes`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 5,
                    text: `Unused Paint & chemicals`,
                    isSelected: false,
                    isOther: false
                  },
                  {
                    id: 6,
                    text: `I don't recycle`,
                    isSelected: false,
                    isOther: false
                  }
                ]
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 23,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 25,
              title: '5.9',
              text: `We'd love your ideas on how you can or might already be reducing your energy use at home`,
              subText: '',
              type: 'input-answers',
              isFinal: false,
              otherAnswer: {
                enabled: false,
                type: 'button',
                answer: {
                  id: 7,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: []
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 24,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            },
            {
              id: 26,
              title: '5.10',
              text: `Is there anything else that you'd like to mention about working from home? Likes/dislikes/suggestions`,
              subText: '',
              type: 'input-answers',
              isFinal: true,
              otherAnswer: {
                enabled: false,
                type: 'button',
                answer: {
                  id: 7,
                  text: "I'm not sure",
                  isSelected: false,
                  isOther: true
                }
              },
              answers: {
                options: []
              },
              requirements: [
                {
                  requirementId: 0,
                  questions: [
                    {
                      questionId: 25,
                      answerIds: [],
                      anyAnswer: true
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      type: Object
    },

    content: {
      default: () => {},
      type: [Object, String]
    }
  },
  data() {
    return {
      srvD: _survey,
      currentQuestion: {},
      surveySessionAnswers: {},
      showRequired: false,
      showResult: false,
      resultObj: {},
      surveyComplete: false
    }
  },
  computed: {
    nextQuestion() {
      return surveyService.getNextQuestion(this.srvD, this.surveySessionAnswers)
    },
    currentQuestionAnswerState() {
      return this.surveySessionAnswers[this.currentQuestion.id]
    },
    selectedAnswer() {
      return this.currentQuestionAnswerState.answers[0]
    },
    questionResponseValid() {
      //This contains the logic required to select and de-select answers
      if (this.currentQuestion.type === 'checkbox-answers') {
        if (this.currentQuestion.isSkippable === true) {
          return true
        }
      }
      return true
    }
  },
  mounted() {
    this.currentQuestion = this.nextQuestion
    console.log('currentQuestion', this.currentQuestion)
    this.updateQuestionResponseState(_.cloneDeep(this.currentQuestion.answers))
  },
  methods: {
    updateQuestionResponseStateAndContinue: function(questionResponseState) {
      this.updateQuestionResponseState(questionResponseState)
      this.testNextQuestion()
    },
    updateQuestionResponseState: function(questionResponseState) {
      let _answers = _.isArray(questionResponseState.options)
        ? questionResponseState.options.filter(option => option.isSelected)
        : [questionResponseState]
      Vue.set(this.surveySessionAnswers, this.currentQuestion.id, {
        questionId: this.currentQuestion.id,
        answers: _answers
      })
    },
    clearAnswers: function() {
      this.surveySessionAnswers = {}
    },

    pushToStore() {
      this.$store.dispatch('levelContentBlocks/insert', {
        type: 'quiz-host',
        order: 99,
        content: this.srvD
      })
    },

    testNextQuestion: function() {
      if (this.currentQuestion.validators.required === true) {
        if (this.currentQuestionAnswerState.answers < 1) {
          this.showRequired = true
          return
        }
      }
      this.resultObj = _.cloneDeep(this.currentQuestionAnswerState.answers[0])
      this.showResult = true
      if (this.resultObj.isCorrect === false) {
        return
      }

      console.log(
        'getNextQuestion',
        surveyService.getNextQuestion(this.content, this.surveySessionAnswers)
      )
      if (this.surveySessionAnswers[this.currentQuestion.id] === undefined) {
        // Answer needs to be present, something has gone wrong
      } else {
        if (this.questionResponseValid) {
          // If question is final flag is true save answers array to Firebase and finish survey
          if (this.currentQuestion.isFinal && !this.nextQuestion) {
            // Firebase save successful survey to cloud
            // this.clearAnswers()
            // // Navigate to end page, TODO probs a v-if on a final page
            // this.$router.push('summary')
            this.$store.dispatch('quizResponses/insert', {
              response: this.surveySessionAnswers
            })
            this.surveyComplete = true
          } else {
            // Load next question
            this.currentQuestion = _.cloneDeep(this.nextQuestion)
            this.updateQuestionResponseState(
              _.cloneDeep(this.currentQuestion.answers)
            )
          }
        }
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn-next {
  margin-top: 60vh;
}

.survey-container {
  margin: 10px 0;
  background: #f7f8f3;
  border-radius: 8px;
  padding: 20px;
}

.survey__result {
  text-align: center;

  .survey__icon {
    font-size: 4rem;
    margin-bottom: $spacing-s;
  }

  .btn-survey {
    margin-top: $spacing-m;
  }
}

.survey__heading {
  font-size: 24px;
  font-weight: 400;
  color: #002c3e;
}

.survey__subtext {
  color: $color-black;
  font-size: 14px;
}

.text-red {
  color: red;
}

.survey__question {
  margin-bottom: 2rem;
}
</style>
