<template>
  <div class="page">
    <div
      class="page-header"
      :style="{ backgroundImage: `url(${headerImage})` }"
    ></div>
    <LevelIndicator :levels="levels" :active-level="level" />
    <Content class="level__content">
      <div class="level__header">
        <h2 class="level__sub-heading">{{ level.category }}</h2>
        <h1 class="level__heading">{{ level.name }}</h1>

        <div class="stats">
          <div class="stats__item">
            <Pill type="primary">{{ level.points }}pts</Pill>
          </div>
          <div class="stats__item">
            <i class="fal fa-alarm-clock stats__icon" />
            {{ level.time }} min
          </div>
        </div>
      </div>

      <FlexContainer flex-direction="column">
        <FlexItem
          v-for="contentBlock in levelContentBlocks"
          :key="contentBlock.id"
          :grow="true"
        >
          <component
            :is="contentBlock.type"
            :content="contentBlock.content"
            class="level__content-block"
            :classes="contentBlock.classes"
            :poster="contentBlock.poster"
          ></component>
        </FlexItem>

        <div class="complete-level-container">
          <h3 class="complete-level__heading">{{ level.completeTitle }}</h3>

          <p>{{ level.completeSubtitle }}</p>
          <Button type="success" @click.native="completeLevel(level.id)">
            Continue to next level
          </Button>
        </div>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { arrayUnion } from 'vuex-easy-firestore'
import SurveyHost from '../../components/global/survey/SurveyHost.vue'
import QuizHost from '../../components/global/survey/QuizHost.vue'
import IdeasHost from '../../components/global/survey/IdeasHost.vue'
import TextBlock from '../../components/global/content-blocks/TextBlock.vue'
import ImageBlock from '../../components/global/content-blocks/ImageBlock.vue'
import VideoBlock from '../../components/global/content-blocks/VideoBlock.vue'
import LevelIndicator from '../../components/journeys/LevelIndicator.vue'
import _ from 'lodash'
export default {
  components: {
    SurveyHost,
    QuizHost,
    TextBlock,
    VideoBlock,
    ImageBlock,
    LevelIndicator,
    IdeasHost
  },
  data: function() {
    return {
      levelId: null,
      journeyId: null
    }
  },
  computed: {
    ...mapGetters({
      levels: 'levels/getLevels',
      level: 'level/getLevel',
      levelContentBlocks: 'levelContentBlocks/getLevelContentBlocks',
      nextLevel: 'levels/getNextLevel'
    }),
    headerImage() {
      return this.$mq === 'sm'
        ? this.level.headerImagePath
        : this.level.headerImagePathDesktop
    }
  },
  mounted() {
    this.levelId = this.$route.params.levelId
    this.journeyId = this.$route.params.journeyId
    this.$store.dispatch('levels/openDBChannel', {
      pathVariables: {
        journeyId: this.journeyId
      },
      clauses: {
        orderBy: ['order']
      }
    })
    this.$store.dispatch('level/openDBChannel', {
      pathVariables: {
        levelId: this.levelId,
        journeyId: this.journeyId
      }
    })
    this.$store.dispatch('levelContentBlocks/openDBChannel', {
      pathVariables: {
        levelId: this.levelId,
        journeyId: this.journeyId
      },
      clauses: {
        orderBy: ['order']
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('levelContentBlocks/closeDBChannel', {
      clearModule: true
    })
    console.log('CLEAR')
    this.$store.dispatch('levels/closeDBChannel', {
      clearModule: true
    })
    this.$store.dispatch('level/closeDBChannel', {
      clearModule: true
    })
    next()
  },
  methods: {
    ...mapActions({}),
    completeLevel() {
      this.$store.dispatch('user/patch', {
        levelsCompleted: arrayUnion(this.level.id)
      })

      if (!this.nextLevel) {
        this.$router.push({
          path: `/journeys/${this.journeyId}/summary`,
          query: {
            confetti: true
          }
        })
      } else {
        this.$router.push(
          `/journeys/${this.journeyId}/levels/${this.nextLevel.id}`
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/stats';
@import '@/assets/sass/components/page-header';

.level__header {
  margin-bottom: 3.2rem;
}

.level__sub-heading {
  font-size: 1.6rem;
}

.level__heading {
  font-size: 2.8rem;
}

.level__content {
  @include respond-above(sm) {
    padding: 2.4rem 15vw !important;
  }
}

.level__content-block {
  margin-bottom: 3.2rem;
}

.complete-level-container {
  background: #f7f8f3;
  width: calc(100% + 40px);
  margin: auto -20px 2rem;
  padding: 20px;
  align-content: center;
  text-align: center;

  @include respond-above(md) {
    width: calc(100% + 30vw); //breaking outside the container
    margin: auto -15vw 2rem;
  }
}

.complete-level__heading {
  font-size: 2.1rem;
  color: $color-black;
}

.pill {
  max-width: 80px;
}
</style>
