<template>
  <div class="swiper-pagination swiper-pagination-bullets">
    <span
      v-for="(level, index) in orderedLevels"
      :key="level.id"
      class="swiper-pagination-bullet"
      :class="classes(level)"
    >
      <span v-if="isLevelActive(level.id)">Level {{ index + 1 }}</span>
      <span v-else-if="level.hasUserCompleted" class="tick"
        ><i class="far fa-check"></i
      ></span>
    </span>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    levels: {
      type: Object,
      required: true
    },
    activeLevel: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderedLevels() {
      const levelsClone = _.cloneDeep(this.levels)
      return Object.values(levelsClone).sort((a, b) => a.order - b.order)
    }
  },
  methods: {
    isLevelActive(levelId) {
      if (this.activeLevel.id === levelId) {
        return true
      }
      return false
    },
    classes(level) {
      if (this.isLevelActive(level.id)) {
        return 'swiper-pagination-bullet-active'
      } else if (level.hasUserCompleted) {
        return 'swiper-pagination-bullet-passed'
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/swiper';

.swiper-pagination {
  position: initial;
  margin: 1.8rem 1.5rem 0;
}

.swiper-pagination-bullet {
  margin: 0 4px;
}
</style>
