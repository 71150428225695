export default {
  title: 'Survey Title',
  id: 0,
  questions: [
    {
      id: 0,
      title: '1.0',
      text:
        'Which ice stores twice the amount of carbon currently in the atmosphere?',
      subText: 'Choose one',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      settings: {
        multipleChoice: false
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Sea Ice',
            isSelected: false,
            isCorrect: false,
            responseText: 'That is incorrect, please try again',
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: 'Land ice',
            responseText: 'That is incorrect, please try again',
            isSelected: false,
            isCorrect: false,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: 'Permafrost',
            responseText: 'Yes, well done',
            isCorrect: true,
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: []
    },
    {
      id: 1,
      title: '1.2',
      text: 'If it melts, extreme climate change will become…',
      subText: 'Choose one',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      settings: {
        multipleChoice: false
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Unlikely',
            isSelected: false,
            isCorrect: false,
            responseText: 'Wrong, it’s worse that that. Please try again',
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: 'A possibility',
            responseText: 'Wrong, it’s worse that that. Please try again',
            isSelected: false,
            isCorrect: false,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: 'Unstoppable',
            responseText: 'Correct, but worrying',
            isCorrect: true,
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 0,
              answerIds: [2],
              anyAnswer: false
            }
          ]
        }
      ]
    },
    {
      id: 2,
      title: '1.3',
      text: 'By when will there be no Antarctic ice in summers?',
      subText: 'Choose one',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      settings: {
        multipleChoice: false
      },
      answers: {
        options: [
          {
            id: 0,
            text: '2040',
            isSelected: false,
            isCorrect: true,
            responseText: 'Correct, time is critical',
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: '2100',
            responseText: 'Wrong, way sooner. Please try again',
            isSelected: false,
            isCorrect: false,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: '2050',
            responseText: 'Wrong, even sooner. Please try again',
            isCorrect: false,
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 1,
              answerIds: [2],
              anyAnswer: false
            }
          ]
        }
      ]
    },
    {
      id: 3,
      title: '1.4',
      text: 'What is a choice we can make as individuals to reduce ice melt?',
      subText: 'Choose one',
      type: 'button-answers',
      isFinal: true,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      settings: {
        multipleChoice: false
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Visit the Antarctic',
            isSelected: false,
            isCorrect: false,
            responseText: 'Sounds nice but wrong',
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: 'Use renewable energy',
            responseText: 'Yes, and you can start here!',
            isSelected: false,
            isCorrect: true,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: 'Sponsor a polar bear',
            responseText: 'Sounds nice but incorrect. Please try again',
            isCorrect: false,
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 2,
              answerIds: [0],
              anyAnswer: false
            }
          ]
        }
      ]
    }
  ]
}
