export default {
  title: 'Survey Title',
  id: 0,
  questions: [
    {
      id: 0,
      title: '1.0',
      text: 'Describe your current work situation',
      subText: 'Choose one',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: true,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      settings: {
        multipleChoice: false
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Homework',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: 'Hybrid (Some office, some home)',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: 'Office/site based',
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: []
    },
    {
      id: 1,
      title: '1.1',
      text: 'How many days/hours do you work a week?',
      subText: 'Estimated',
      type: 'dropdown-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 99,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: '1',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: '2',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: '3',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 3,
            text: '4',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 4,
            text: '5',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 5,
            text: '6',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 6,
            text: '7',
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 0,
              answerIds: [0],
              anyAnswer: false
            }
          ]
        }
      ]
    },
    {
      id: 2,
      title: '1.2',
      text: 'When did you start working at home',
      subText: '',
      type: 'date-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 1,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 3,
      title: '1.3',
      text: 'Does anyone else in your household work from home? How many?',
      subText: '',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: true,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: []
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 2,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 4,
      title: '2.1',
      text: 'How many days do you work at home per week?',
      subText: '',
      type: 'dropdown-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 99,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: [
        {
          id: 0,
          text: '1',
          isSelected: false,
          isOther: false,
          score: 0
        },
        {
          id: 1,
          text: '2',
          isSelected: false,
          isOther: false,
          score: 0
        },
        {
          id: 2,
          text: '3',
          isSelected: false,
          isOther: false,
          score: 0
        },
        {
          id: 3,
          text: '4',
          isSelected: false,
          isOther: false,
          score: 0
        },
        {
          id: 4,
          text: '5',
          isSelected: false,
          isOther: false,
          score: 0
        },
        {
          id: 5,
          text: '6',
          isSelected: false,
          isOther: false,
          score: 0
        },
        {
          id: 6,
          text: '7',
          isSelected: false,
          isOther: false,
          score: 0
        }
      ],
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 0,
              answerIds: [1],
              anyAnswer: false
            }
          ]
        }
      ]
    },
    {
      id: 5,
      title: '2.2',
      text: 'How many days you work at your office/site per week?',
      subText: '',
      type: 'dropdown-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: '1',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 1,
            text: '2',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 2,
            text: '3',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 3,
            text: '4',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 4,
            text: '5',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 5,
            text: '6',
            isSelected: false,
            isOther: false,
            score: 0
          },
          {
            id: 6,
            text: '7',
            isSelected: false,
            isOther: false,
            score: 0
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 4,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 6,
      title: '2.3',
      text: 'When did you start working at home',
      subText: '',
      type: 'date-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 5,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 7,
      title: '2.4',
      text:
        'Does anyone else in your household work from home, if so, how many?',
      subText: "Type '0' if it's just you.",
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 6,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 8,
      title: '4.1',
      text:
        'In an average week, how do you get to work? Select the methods of transport you use',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      settings: {
        multipleChoice: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Motorbike',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: 'Walk',
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: 'Bicycle',
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: 'Car',
            isSelected: false,
            isOther: false
          },
          {
            id: 4,
            text: 'Car pool',
            isSelected: false,
            isOther: false
          },
          {
            id: 5,
            text: 'Bus',
            isSelected: false,
            isOther: false
          },
          {
            id: 6,
            text: 'Train',
            isSelected: false,
            isOther: false
          },
          {
            id: 7,
            text: 'Tram',
            isSelected: false,
            isOther: false
          },
          {
            id: 8,
            text: 'Other, please specify',
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 7,
              answerIds: [],
              anyAnswer: true
            }
          ]
        },
        {
          requirementId: 2,
          questions: [
            {
              questionId: 10,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 9,
      title: '4.2',
      text:
        'What is the main factor in deciding how you’re going to get to work?',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      settings: {
        multipleChoice: false
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Time saving',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: 'Reduced stress',
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: 'Financial savings',
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: 'Flexbility',
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: 'Less wear and tear on car',
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: 'Convenience',
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 8,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 10,
      title: '3.1',
      text: 'How many days/hours do you work a week?',
      subText: '',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 4,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 0,
              answerIds: [2],
              anyAnswer: false
            }
          ]
        }
      ]
    },
    {
      id: 11,
      title: '4.3',
      text:
        'How far do you estimate you travel to get to work from home each day?',
      subText: '',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      settings: {
        multipleChoice: false
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 9,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 12,
      title: '4.4',
      text:
        'In an average week, how much of each of your chosen methods do you use?',
      subText: '',
      type: 'slider-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 11,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 13,
      title: '4.5',
      text: 'How long do you spend on average commuting to work and from work?',
      subText: 'Getting to work',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 12,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 14,
      title: '4.5b',
      text: 'How long do you spend on average commuting to work and from work?',
      subText: 'Getting home',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 13,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 15,
      title: '4.6',
      text: 'How much money do you spend on your weekly commute?',
      subText: '£ Spend',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 14,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 16,
      title: '4.7',
      text:
        'Is there anything that could make your commute better or make you consider the transport you use?',
      subText: '',
      type: 'input-answers',
      isFinal: true,
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {},
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 15,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 17,
      title: '5.1',
      text:
        'What equipment do you use for work? Please give name and model if possible, and if it is your own',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Laptop',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: 'Desktop',
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: 'Extra Screen',
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: 'Work Mobile',
            isSelected: false,
            isOther: false
          },
          {
            id: 4,
            text: 'Other, please describe',
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 16,
              answerIds: [],
              anyAnswer: true
            },
            {
              questionId: 7,
              answerIds: [],
              anyAnswer: true
            }
          ]
        },
        {
          requirementId: 0,
          questions: [
            {
              questionId: 3,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 18,
      title: '5.2',
      text: 'What is the main light source for your workspace?',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        answer: {
          id: 9,
          text: '',
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'LED',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: 'Halogen',
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: 'Incandescent',
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: 'Compact fluorescent (CFL)',
            isSelected: false,
            isOther: false
          },
          {
            id: 4,
            text: 'Not sure',
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 17,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 19,
      title: '5.3',
      text: 'Who is your energy supplier?',
      subText: '',
      type: 'dropdown-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: true,
        type: 'button',
        answer: {
          id: 2,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Company 1',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: `Company 2`,
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 18,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 20,
      title: '5.4',
      text: 'Do you know your tariff?',
      subText: '',
      type: 'dropdown-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: true,
        type: 'button',
        answer: {
          id: 2,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Tariff 1',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: `Tariff 2`,
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 19,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 21,
      title: '5.5',
      text: 'What type of boiler heats your home?',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        type: 'button',
        answer: {
          id: 6,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Gas',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: `Oil`,
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: `Elec`,
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: `Biomass`,
            isSelected: false,
            isOther: false
          },
          {
            id: 4,
            text: `Heat Pump`,
            isSelected: false,
            isOther: false
          },
          {
            id: 5,
            text: `Don't know`,
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 20,
              answerIds: [],
              anyAnswer: true
            }
          ]
        },
        {
          requirementId: 1,
          questions: [
            {
              questionId: 19,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 22,
      title: '5.6',
      text: 'Do you have a SMART meter?',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        type: 'button',
        answer: {
          id: 6,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Yes',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: `No`,
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: `Not Sure`,
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 21,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 23,
      title: '5.7',
      text:
        'During warmer months, do you use anything to keep your workspace cool?',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        type: 'button',
        answer: {
          id: 6,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Air con',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: `Fan`,
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: `Just open windows`,
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: `Nothing in particular`,
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 22,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 24,
      title: '5.8',
      text: 'Do you recycle?',
      subText: '',
      type: 'button-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        type: 'button',
        answer: {
          id: 7,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: [
          {
            id: 0,
            text: 'Food waste',
            isSelected: false,
            isOther: false
          },
          {
            id: 1,
            text: `Paper & Card`,
            isSelected: false,
            isOther: false
          },
          {
            id: 2,
            text: `Tins & Glass`,
            isSelected: false,
            isOther: false
          },
          {
            id: 3,
            text: `Garden Waste`,
            isSelected: false,
            isOther: false
          },
          {
            id: 4,
            text: `Clothes`,
            isSelected: false,
            isOther: false
          },
          {
            id: 5,
            text: `Unused Paint & chemicals`,
            isSelected: false,
            isOther: false
          },
          {
            id: 6,
            text: `I don't recycle`,
            isSelected: false,
            isOther: false
          }
        ]
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 23,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 25,
      title: '5.9',
      text: `We'd love your ideas on how you can or might already be reducing your energy use at home`,
      subText: '',
      type: 'input-answers',
      isFinal: false,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        type: 'button',
        answer: {
          id: 7,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: []
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 24,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    },
    {
      id: 26,
      title: '5.10',
      text: `Is there anything else that you'd like to mention about working from home? Likes/dislikes/suggestions`,
      subText: '',
      type: 'input-answers',
      isFinal: true,
      validators: {
        required: true
      },
      otherAnswer: {
        enabled: false,
        type: 'button',
        answer: {
          id: 7,
          text: "I'm not sure",
          isSelected: false,
          isOther: true
        }
      },
      answers: {
        options: []
      },
      requirements: [
        {
          requirementId: 0,
          questions: [
            {
              questionId: 25,
              answerIds: [],
              anyAnswer: true
            }
          ]
        }
      ]
    }
  ]
}
