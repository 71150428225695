import _ from 'lodash'
export default class SurveyService {
  constructor() {}
  // Static class no internal state to be stored here

  getNextQuestion(surveyData, sessionAnswers) {
    console.log(sessionAnswers)
    try {
      // Check requirements to find next Q in service
      if (surveyData === undefined) {
        // Pass the survey in dummy!
        throw 'No survey passed to service'
      }
      if (!_.isObject(sessionAnswers)) {
        // Pass the survey in dummy!
        throw 'Answers not in correct format'
      }
      if (!_.isArray(surveyData.questions)) {
        // Pass the survey in dummy!
        throw 'Questions not in correct format'
      }
      if (surveyData.questions.length < 1) {
        // Pass the survey in dummy!
        throw 'No questions in the survey'
      }

      // Initial Case - Always take the first question. Otherwise we are chicken and egg
      if (_.size(sessionAnswers) === 0) {
        return surveyData.questions[0]
      }

      // Basic Flow ->
      // Iterate over the questions
      // Check whether the question has been answered
      // If it has not been answered lets check its requirements to see if it should be shown based on the users previous answers
      // It is a fifo check so as soon as a question has been matched we show that and don't bother checking the rest of the questions
      // Fail-safe if question requirement conflicts are present (Two questions are trying to be shown at the same time) it will just show both sequentially not perfect but at least it does not fall over

      for (var i = 1, len = surveyData.questions.length; i < len; i++) {
        // Search only unanswered questions
        var unansweredQuestion = true
        _.forOwn(sessionAnswers, answeredQuestion => {
          if (surveyData.questions[i].id === answeredQuestion.questionId) {
            unansweredQuestion = false
            // return false
          }
          // return true
        })
        if (unansweredQuestion) {
          if (
            this.validateQuestionRequirements(
              surveyData.questions[i],
              sessionAnswers
            )
          ) {
            console.log('Loaded Question: ' + surveyData.questions[i].title)
            return surveyData.questions[i]
          }
        }
      }
      console.log('No questions matched, requirements validation error')
      return null
    } catch (error) {
      console.error(error)
      return error
    }
  }

  validateQuestionRequirements(question, sessionAnswers) {
    var validatedRequirement = false
    for (var i = 0, len = question.requirements.length; i < len; i++) {
      if (
        this.validateRequirementQuestions(
          question.requirements[i],
          sessionAnswers
        )
      ) {
        // A requirement has been satisfied
        validatedRequirement = true
      }
    }
    return validatedRequirement
  }

  validateRequirementQuestions(requirement, sessionAnswers) {
    var validRequiredQuestionStates = []
    for (var i = 0, len = requirement.questions.length; i < len; i++) {
      if (
        this.validateQuestionAnswers(requirement.questions[i], sessionAnswers)
      ) {
        // All required answer ids for the current question have been verified to exist so add it to the verified question states
        validRequiredQuestionStates[i] = requirement.questions[i]
      } else {
        return false
      }
    }
    // Test to see if required state array matches the validated array for the requirement
    var requirementsValidated =
      validRequiredQuestionStates.length === requirement.questions.length &&
      validRequiredQuestionStates.every(function(element, index) {
        return element === requirement.questions[index]
      })
    if (requirementsValidated) {
      // All questions have been verified to have all the required answers
      return true
    } else {
      return false
    }
  }

  // We have the an individual question that needs verifiying from the current session answers
  validateQuestionAnswers(question, sessionAnswers) {
    console.log('validate: ' + question + ' ' + sessionAnswers)
    console.log(question)
    console.log(sessionAnswers)
    // Test to see if Session Answer(s) exist for the question being input
    var matchedAnsweredQuestion = undefined

    _.findKey(sessionAnswers, function(answeredQuestion) {
      if (answeredQuestion.questionId === question.questionId) {
        matchedAnsweredQuestion = sessionAnswers[answeredQuestion.questionId]
        return true
      }
    })

    if (matchedAnsweredQuestion !== undefined) {
      // Answers have been found for question

      // Test to see if any answer is acceptable
      if (question.anyAnswer) {
        return true
      }
      if (
        matchedAnsweredQuestion.answers.length !== question.answerIds.length
      ) {
        console.log(
          'Found Matching Question ID however incorrect amount of answers selected to meet requirements'
        )
        return false
      }
      // Test to see if the required answer ids are present in the matched session answer
      var questionAnswersValid = question.answerIds.every(function(
        requirementAnswerId
      ) {
        var answerIdMatched = false
        for (
          var i = 0, len = matchedAnsweredQuestion.answers.length;
          i < len;
          i++
        ) {
          // An Answer ID has been matched
          if (requirementAnswerId === matchedAnsweredQuestion.answers[i].id) {
            answerIdMatched = true
          }
        }
        return answerIdMatched
      })

      return questionAnswersValid
    } else {
      // No matched response was found
      return false
    }
  }
}
