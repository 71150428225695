<template>
  <div>
    <div v-for="(answer, index) in localAnswers.options" :key="index">
      <div @click="selectAnswer(answer, index)">
        <Button
          size="small"
          :type="getType(answer.isSelected)"
          class="btn btn-default btn-block btn-survey"
        >
          {{ answer.text }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'ButtonAnswers',
  props: {
    answers: {
      default: () => {},
      type: Object
    },
    other: {
      default: () => {},
      type: Object
    },
    settings: {
      default: () => {},
      type: Object
    }
  },
  data: function() {
    return {
      localAnswers: {}
    }
  },

  mounted() {
    this.localAnswers = _.cloneDeep(this.answers)
  },

  methods: {
    getType(isSelected) {
      return isSelected ? 'primary' : 'quinary'
    },
    selectAnswer: function(answerValue, index) {
      if (!this.settings?.multipleChoice) {
        for (let i = 0; i < this.localAnswers.options.length; i++) {
          const answer = this.localAnswers.options[i]

          if (i === index) answerValue.isSelected = !answerValue.isSelected
          else answer.isSelected = false
        }
      } else {
        answerValue.isSelected = !answerValue.isSelected
      }
      this.$emit('answer-changed', this.localAnswers)
    }
  }
}
</script>

<style>
.btn-survey {
  margin: 5px;
}
</style>
